.button {
  border: 1px solid #01314a;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;
  text-align: center;
  background-color: #01314a;
  border-radius: 2rem;
  color: #fff;
  height: 64px;
  text-decoration: none !important;
  width: 100%;
  white-space: nowrap;
  span {
    text-decoration: none !important;
  }

  &.secondaryButton {
    background-color: #ffffff;
    color: #01314a;
  }

  &.mediumButton {
    height: 38px;
  }

  &.blockButton {
    display: block;
    width: 100%;
    padding: 1rem;
  }

  &:disabled {
    cursor: default;
    background-color: #e5e5e5;
    border-color: #e5e5e5;
    color: #808080;
  }

  & a {
    display: block;
    width: 100%;
  }

  @media (hover: hover) {
    &:hover:enabled {
      background: #0083c8;
      cursor: pointer;
      color: #fff;
    }

    &.secondaryButton {
      &:hover:enabled {
        background: #0083c8;
        color: #fff;
      }
    }
  }
  @media (max-width: 500px) {
    padding: 8px;
  }
}
